// Bio.js

import { useState } from "react";
import React from 'react';
import "./Services.css";
import {motion} from 'framer-motion';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';



function Services({greek}){
  const [category, setCategory] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  // CATEGORIES STUFF
  const categories = [
    {"en":"","gr":""},
    {"en":"Branding", "gr":"Εταιρική Ταυτότητα"},
    {"en":"Books", "gr":"Βιβλία"},
    {"en":"Brochures", "gr":"Έντυπα"},
    {"en":"Posters", "gr":"Αφίσες"},
    {"en":"Advertising", "gr":"Καταχωρίσεις"},
    {"en":"Packaging", "gr":"Συσκευασίες"},
    {"en":"Promotional", "gr":"Προωθητικά είδη"},
    {"en":"Invitations", "gr":"Προσκλήσεις"},
    {"en":"Outdoor Design", "gr":"Εξωτερική Διαφήμιση"},
    {"en":"Car Wrapping", "gr":"Ντύσιμο Οχημάτων"},
    {"en":"Media & Animation", "gr":"Media & Animation"},
    {"en":"Social", "gr":"Social"},
    {"en":"Exhibition Design", "gr":"Σχεδιασμός Εκθεσιακών Περιπτέρων"}
  ]

  const categoriesDescriptions = [
    {"en":"","gr":""},
    {"en":"We take care of branding for....", "gr":"Όλα όσα αφορούν την εταιρική Ταυτότητα..."},
    {"en":"From digital design to covers for books....", "gr":"Από σχέδια έως εξώφυλλα σε τιμές τρομερές..."},
    {"en":"Brochures info...", "gr":"Έντυπα info..."},
    {"en":"Posters info...", "gr":"Αφίσες info..."},
    {"en":"Advertising info...", "gr":"Καταχωρίσεις info..."},
    {"en":"Packaging info...", "gr":"Συσκευασίες info..."},
    {"en":"Promotional info...", "gr":"Προωθητικά είδη info..."},
    {"en":"Invitations info...", "gr":"Προσκλήσεις info..."},
    {"en":"Outdoor Design info...", "gr":"Εξωτερική Διαφήμιση info..."},
    {"en":"Car Wrapping info...", "gr":"Ντύσιμο Οχημάτων info..."},
    {"en":"Media and Animation info...", "gr":"Media and Animation info..."},
    {"en":"Social info...", "gr":"Social info..."},
    {"en":"Exhibition Design info...", "gr":"Σχεδιασμός Εκθεσιακών Περιπτέρων info..."}
  ]


  const findCategoryIndexByName = (name) => {
    for (let i = 0; i < categories.length; i++) {
      // console.log("in loop categories[i].en.toUpperCase()=", categories[i].en.toUpperCase() , "englishName=", englishName);
        if (categories[i].en.toUpperCase() === name.toUpperCase()) {
            return i;
        }
        if (categories[i].gr.toUpperCase() === name.toUpperCase()) {
          return i;
      }
    }
    console.error("Category not found -", name);
    return -1; // If category is not found
  }

  const getCategory = (index) => {
    if (index < categories.length && index >= 0){
        if (!greek)return categories[index].en;
        else return categories[index].gr;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const getCategoryInfo = (index) => {
    if (index < categories.length && index >= 0){
        if (!greek)return categoriesDescriptions[index].en;
        else return categoriesDescriptions[index].gr;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const getEnglishCategory = (index) => {
    if (index < categories.length && index >= 0){
        return categories[index].en;
    }
    else if(index===-1){
        console.error("Category array index not found");
    }
    else{
        console.error("Category array index out of bounds");
    }
  }

  const handleHover = (e)=>{
    // console.log(e.target.textContent);
    setCategory(e.target.textContent);
    setIsHovered(true);
  }

    return (
      <div>
      <div className="pageBody"/>
      <div className={`blackOverlay ${isHovered ? 'darkened' : ''}`}></div>

      <motion.div id='column1' className={`services-overlay ${isHovered&&"services-details"}`} initial={{x:"20vw"}} animate={{x:0, transition: { duration: 0.4, delay:0 , ease: "backInOut"}}} exit={{opacity:0,  transition: { duration: 1, ease: "backOut" }}}>
          <div className="content-wrapper justify-content-center">
              <FontAwesomeIcon icon={faInfoCircle} size='2x' />
              <div className='h5 fw-normal txt-content content-title'>{getCategory(findCategoryIndexByName(category))}<hr/></div>
              <div className='fw-light txt-content content-title'>{getCategoryInfo(findCategoryIndexByName(category))}</div>
          </div>
      </motion.div>

      
      
      <div>
      <motion.div initial={{opacity:0}} animate={{opacity:100,scale:1, transition: { duration: 0.8,  delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0,scale:0, transition: { duration: 0.3, ease: "easeInOut" }}}>
        <br/>
        <motion.div initial={{x:"50vw"}} animate={{x:0  ,transition: { duration: 0.8, ease: "backInOut", delay:0.3 }}}>
            <div className='h1 fw-light text-decoration'>{!greek?"Services we provide":"Υπηρεσίες που παρέχουμε"}</div>
            <div className='h5 fw-light text-decoration'>{!greek?"Click to see projects of the selected category!":"Επιλέξτε κατηγορία και δείτε σχετικά έργα!"}</div>
        </motion.div>
        <br/><br/>
        <div className='container-fluid services-container'> 
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.5 }}}>
            <Link to='/projects?category=Branding' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Branding":"Εταιρική Ταυτότητα"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.55 }}}>
            <Link to='/projects?category=Books' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Books":"Βιβλία"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.6 }}}>
            <Link to='/projects?category=Brochures' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.1s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Brochures":"Έντυπα"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.65 }}}>
            <Link to='/projects?category=Posters' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.1s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Posters":"Αφίσες"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.7 }}}>
            <Link to='/projects?category=Advertising' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.2s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Advertising":"Καταχωρίσεις"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.75 }}}>
            <Link to='/projects?category=Packaging' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.2s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Packaging":"Συσκευασίες"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.8 }}}>
            <Link to='/projects?category=Promotional' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.3s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Promotional":"Προωθητικά είδη"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.85 }}}>
            <Link to='/projects?category=Invitations' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.3s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Invitations":"Προσκλήσεις"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.7 }}}>
            <Link to='/projects?category=Outdoor Design' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.4s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Outdoor Design":"Εξωτερική Διαφήμιση"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.7 }}}>
            <Link to='/projects?category=Car Wrapping' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.4s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Car Wrapping":"Ντύσιμο Οχημάτων"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.75 }}}>
            <Link to='/projects?category=Media and Animation' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.5s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Media & Animation":"Media & Animation"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"-30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.75 }}}>
            <Link to='/projects?category=Social' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.5s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Social":"Social"}</motion.div></Link>
          </motion.div>
          <motion.div initial={{opacity:0, x:"30vw"}} animate={{opacity:100, x:0 ,transition: { duration: 0.7, ease: "backOut", delay:0.8 }}}>
            <Link to='/projects?category=Exhibition Design' style={{color : "inherit", textDecoration:"none"}}><motion.div className='h2 fw-light text-decoration fa-flip category-option' style={{"--fa-animation-delay":"0.5s"}} onHoverStart={handleHover} onHoverEnd={() => setIsHovered(false)}>{!greek?"Exhibition Design":"Σχεδιασμός Εκθεσιακών Περιπτέρων"}</motion.div></Link>
          </motion.div>
        </div>
      </motion.div>
      </div>
      <br/> <br/> <br/> <br/><br/><br/><br/><br/>
      </div>
    );
  }
export default Services;