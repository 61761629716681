// Navbar.js

import React, { Component, useEffect } from 'react';
import logo from "../images/logos/logo.jpg"
import { Link, useLocation } from "react-router-dom"
import { useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web'
import englishFlag from "../images/flags/uk-flag.png";
import greekFlag from "../images/flags/greek-flag.png";
import "./Navbar.css";

function Navbar({ handleGreek, greek }){ 
    const location = useLocation();
    const inputRef = useRef(null);
    const [selected,setSelected] = useState(location.pathname);

    const [props] = useSpring(() => ({
        from: { opacity: 0, x: '-100%' },
        to: { opacity: 1 , x: '0%' },
      }));

    function collapseHandle(e){
        if (window.innerWidth <= 991.5) {
            inputRef.current.click();
        }
    }
    
    useEffect(() => {
        setSelected(location.pathname);
    }, [location.pathname]);

    return (
        <div>
          <nav className="navbar navbar-expand-lg bg-body-tertiary" role="navigation">
                    <div className="container-fluid">
                        <Link to="/"  className="nav-link m-auto" >
                            <img src={logo} className="img-fluid logo-class" alt=""/>
                        </Link>
                        {/* <div className="vr" style={{ height: 'auto' }}></div> */}
                        <br></br>
                        <button ref={inputRef} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul style={props} className="navbar-nav m-auto">
                                <li className={`nav-item ${selected==="/"?"selected":"fw-normal"}`} >
                                    <Link to="/" className={`nav-link ms-3 me-3 text-decoration`} onClick={()=>collapseHandle()} >{!greek?"Home":"Αρχική"}</Link>
                                </li>
                                <li className={`nav-item ${selected==="/bio"?"selected":"fw-normal"}`}>
                                    <Link to="/bio" className={`nav-link ms-3 me-3 text-decoration`} onClick={()=>collapseHandle()} >{!greek?"About":"Σχετικά με εμάς"}</Link>
                                </li>
                                <li className={`nav-item ${selected==="/services"?"selected":"fw-normal"}`}>
                                    <Link to="/services" className={`nav-link ms-3 me-3 text-decoration`} onClick={()=>collapseHandle()} >{!greek?"Services":"Υπηρεσίες"}</Link>
                                </li>
                                <li className={`nav-item ${selected==="/projects"?"selected":"fw-normal"}`}>
                                    <Link to="/projects" className={`nav-link ms-3 me-3 text-decoration`} onClick={()=>collapseHandle()}>{!greek?"Projects":"Έργα"}</Link>
                                </li>
                                <li className={`nav-item ${selected==="/clients"?"selected":"fw-normal"}`}>
                                    <Link to="/clients" className={`nav-link ms-3 me-3 text-decoration`} onClick={()=>collapseHandle()}>{!greek?"Clients":"Πελάτες"}</Link>
                                </li>
                                <li className={`nav-item ${selected==="/contact"?"selected":"fw-normal"}`}>
                                    <Link to="/contact" className={`nav-link ms-3 me-3 text-decoration`} onClick={()=>collapseHandle()}>{!greek?"Contact":"Επικοινωνία"}</Link>
                                </li>
                                <li className="nav-item">
                                        <div className={`btn btn-light language ${greek?"language-gr":"language-en"}`} onClick={()=>handleGreek()}>{greek?"en":"ελ"}</div>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </nav>
        </div>
    );
}

export default Navbar;