// Navbar.js

import React from 'react';
import { useRef } from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import { useState, useEffect } from 'react';
import "./Project.css";


function Project({project, handleBack, greek}){     
    const [filteredPhotos, setFilteredPhotos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(1);
    const [modalImages, setModalImages] = useState([]);
    const [currentCategory,setCurrentCategory] = useState("ALL");
    const renderedPics = useRef({items:false, items2:false, items3:false, items4:false, items5:false, items6:false, items7:false, items8:false, items9:false, items10:false});

    const handleCategory = (cat) =>{
        setCurrentCategory(cat);
        openModal();
    };

    const categoriesTable = [
        {"en":"All", "gr":"Όλα"},
        {"en":"Branding", "gr":"Εταιρική Ταυτότητα"},
        {"en":"Books", "gr":"Βιβλία"},
        {"en":"Brochures", "gr":"Έντυπα"},
        {"en":"Posters", "gr":"Αφίσες"},
        {"en":"Advertising", "gr":"Καταχωρίσεις"},
        {"en":"Packaging", "gr":"Συσκευασίες"},
        {"en":"Promotional", "gr":"Προωθητικά είδη"},
        {"en":"Invitations", "gr":"Προσκλήσεις"},
        {"en":"Outdoor Design", "gr":"Εξωτερική Διαφήμιση"},
        {"en":"Car Wrapping", "gr":"Ντύσιμο Οχημάτων"},
        {"en":"Media & Animation", "gr":"Media & Animation"},
        {"en":"Social", "gr":"Social"},
        {"en":"Exhibition Design", "gr":"Σχεδιασμός Εκθεσιακών Περιπτέρων"}
      ];
    const openModal = () => {
        document.getElementById("my-modal").style.display="inline-flex";
    };

    const closeModal = () => {
        document.getElementById("my-modal").style.display="none";
        setCurrentSlide(1);
    };

    const showSlides = () => {
        // console.log("show_slides");
            for (let i = 1; i <= filteredPhotos.length ; i++) {
                const element = document.getElementById(`modal-photo${project.id}${i}`);
                if (element) {
                  element.style.display = (i !== currentSlide) ? "none" : "block";
                }
            }
    };

    function changeSlide(n){
        if ((currentSlide+n) <= filteredPhotos.length && (currentSlide+n) >=1)setCurrentSlide(currentSlide + n);
        else if ((currentSlide+n) < 1)setCurrentSlide(filteredPhotos.length);
        else setCurrentSlide(1);
    };
    
      const findCategoryIndexByEnglishName = (englishName) => {
        for (let i = 0; i < categoriesTable.length; i++) {
            if (categoriesTable[i].en.toUpperCase() === englishName.toUpperCase()) {
                return i;
            }
        }
        return -1; // If category is not found
    }
    
      const getCategory = (index) => {
        if (index < categoriesTable.length && index >= 0){
            if (!greek)return categoriesTable[index].en;
            else return categoriesTable[index].gr;
        }
        else if(index===-1){
            console.error("Category array index not found");
        }
        else{
            console.error("Category array index out of bounds");
        }
        
    }

    
    useEffect(() => {
        setFilteredPhotos(project.photos.filter(photo => photo.category === currentCategory));
    }, [currentCategory]);

    useEffect(() => {
        const items = filteredPhotos.map((photo, index) => (
            <motion.div tag={index+1} id={`modal-photo${project.id}${index+1}`} className={`my-slides slide${index+1}` } key={`modal-${project.id}-${index+1}`} initial={{opacity:0}} animate={{opacity:100 ,transition: { duration: 0.5, ease: "easeInOut", delay:0 }}} exit={{opacity:0,  transition: { duration: 0.2, ease: "easeInOut" }}} style={{"display":index === 0 ? "block" : "none", "justifyContent": "center"}}> 
                    <img key={`modal-photo${project.id}${index+1}`} src={photo.path} className="portrait-image" alt={`Slide ${index + 1}`} />
            </motion.div>
        ));
        setModalImages(items);
    }, [filteredPhotos]);


    useEffect(() => {
        showSlides();
    }, [currentSlide]);

    return (
        <div className='body'>
            
                <motion.div className='top-side' initial={{opacity:0,  transition: { duration: 0.2, ease: "backOut" }}} animate={{opacity:1,  transition: { duration: 0.2, ease: "backOut" }}} exit={{opacity:0,  transition: { duration: 0.2, ease: "backOut" }}}>
                    <div className="h1 fw-light text-decoration txt-content d-flex align-items-center">
                        <div className="btn btn-danger-subtle back-button" onClick={handleBack} style={{ textTransform: "none" }}>
                            <i className="fa-solid fa-arrow-left"></i> {!greek?"Back":"Πίσω"}
                        </div>
                        <div className="h3 fw-light d-flex mx-auto mt-3">{project.name} - {project.date}</div>
                    </div>
                    <hr/>
                </motion.div>

                
                <motion.div id="my-modal" className="my-modal container-fluid row align-items-start justify-content-center" style={{"display":"none"}} initial={{opacity:0}} animate={{opacity:1, transition: { duration: 0.2, delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0, transition: { duration: 0.2, ease: "easeInOut" }}}>

                    <div key={`modal-header${project.id}$`} className="row align-items-center justify-content-center modal-header">

                        <div className="col-3">
                            <div className='col fw text-decoration numbers p-1'>{getCategory(findCategoryIndexByEnglishName(currentCategory))}</div>
                        </div> 
                        <div className="col-2">
                            <div className='col btn btn-lg btn-nextprev' onClick={()=>changeSlide((-1))}>&#10094;</div>
                        </div>        

                        <div className="col-2">
                            <div className='col h5 fw text-decoration numbers'>{currentSlide}/{filteredPhotos.length}</div>
                        </div>
                        
                        <div className="col-2">
                            <div className='col btn btn-lg btn-nextprev' onClick={()=>changeSlide((1))}>&#10095;</div>
                        </div>

                        <div className="col-1">
                        
                        </div> 
                        
                        <div className="col-2">
                            <div className='btn btn-lg close mr-4' onClick={closeModal}><i className="fa-solid fa-x fa-beat"></i></div>
                        </div>
                    </div>

                    <motion.div className="modal-content row" onKeyUpCapture={closeModal}>
                           {modalImages}    
                    </motion.div>

                    <br/><br/><br/>
                    
                </motion.div>
                
            
                <div className='d-flex align-items-start justify-content-center container'>
                    {/* START OF FILTERS-MENU */}
                        
                    <motion.div id='column1' className='menu-side col' initial={{y:"10vh"}} animate={{y:0, transition: { duration: 0.4, delay:0.2 , ease: "backIn"}}} exit={{opacity:0,  transition: { duration: 0.2, ease: "backOut" }}}>
                        <div className="content-wrapper justify-content-center">
            
                            <div className='h5 fw-normal txt-content content-title'>{!greek?"Content By Category":"Περιεχόμενο Ανα Κατηγορία"}<hr/></div>
                            
                            {project.categories?.map((category, index) => {
                                                return(
                                                    <motion.div key={`category_${project.id}_${index}`} initial={{opacity:0}} animate={{opacity:100 ,transition: { duration: 1.2, ease: "easeInOut", delay:0.4 }}} exit={{opacity:0,transition: { duration: 0.2, ease: "easeOut" }}}>
                                                    <div style={{ textTransform: "none" }} key={`category_${project.id}_category${index}`} id={`category_${index}`} className='btn btn-lg btn-dark mt-1 category-button d-flex' onClick={()=>handleCategory(category)}>
                                                        {getCategory(findCategoryIndexByEnglishName(category))}
                                                    </div>
                                        
                                                    </motion.div>)})
                                                }
                            <br/>
                        </div>


                        <div className='container' >
                            <div className='big-wrapper m-auto'>
                                <div className='h5 fw-normal txt-content fw-regular'>  {!greek?"About this project":"Σχετικά με αυτό το έργο"}</div>
                                <hr/>
                       
                                <div className='info-container'>
                                <div className='text-desc fw-normal mb-3'> <span className='fw-bold mr-auto'>{!greek?"Client" :"Πελάτης"}</span><hr style={{"width":"50%", "margin":"auto"}}/>&nbsp;{project.client}</div>                    
                                <div className='text-desc fw-normal m-auto'> <span className='fw-bold'>{!greek?"Info":"Πληροφορίες"}</span><hr style={{"width":"50%", "margin":"auto"}}/>&nbsp; {project.about}</div>
                                </div>
                  
                            </div>
                        </div>
                    </motion.div>

                    <motion.div id='column2' className='carousel-side col' initial={{opacity:0, scale:0}} animate={{opacity:100 , scale:1 ,transition: { duration: 0.6, ease: "backOut", delay:0.6 }}} exit={{opacity:0,  transition: { duration: 0.5, ease: "backOut" }}} >
                            <div className="content-wrapper">
                            <div className='h5 fw-normal txt-content content-title carousel-side-title'>{!greek?"Preview Carousel":"Καρουζέλ Εικόνων"}<hr/></div>
                            <div id="carousel" className="carousel slide my-carousel">
                                <div className="carousel-indicators">
                                {project.photos?.map((photo, index) => {
                                                return(
                                                    <button key={`photo_shortcut_${index}`} type="button" data-bs-target="#carousel" data-bs-slide-to={index} className={`${index===0&&'active'} dot`} aria-current={`page`} aria-label={`Slide ${index}`}></button>
                                                    )})
                                                }
                                </div>
                                <div className="carousel-inner">
                                {project.photos?.map((photo, index) => {
                                                return(
                                                    <div className={`carousel-item ${index===0&&'active'}`} key={`carousel-item-${index}`}>
                                                        <div className="image-container" style={{ position: "relative", overflow: "hidden" }}>
                                                        <motion.img key={`photo_${index}`} initial={{opacity:0, scale:0, rotateZ:"30deg"}} animate={{opacity:100 , scale:1, rotateZ:"0deg",transition: { duration: 0.8, ease: "easeIn", delay:0.3 }}} exit={{opacity:0,  transition: { duration: 0.2, ease: "easeInOut" }}} id={`photo_${index}`} className='d-block w-100 my-image' src={photo.path}></motion.img>
                                                        </div>
                                                    </div>)})
                                                }
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                                    {/* <span className="carousel-control-prev-icon" aria-bs-hidden="true">prev</span> */}
                                    <span className="btn btn-lg btn-carousel carousel-prev">{"<"}</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                                    {/* <span className="carousel-control-next-icon" aria-bs-hidden="true">next</span> */}
                                    <span className="btn btn-lg btn-carousel carousel-next">{">"}</span>
                                </button>
                            </div>
                            </div>
                    </motion.div>
                </div>
            <br/>
        </div>
    );
}

export default Project;